// import React from 'react'
import React, { useState } from 'react'




export default function TextForm(props) {
  const [text, setText] = useState('')
  const onclk = () => {
    setText(text.toUpperCase())
    if (text!=="") {
      
      props.alrtFunc("Converted to Upper case", "info")
    }

  }
  const onclkclear = () => {
    setText("")
    if (text!=="") {
    props.alrtFunc("Cleared", "info")
    }
  }

  const handlechg = (event) => {

    setText(event.target.value)
  }
 
  return (
    <>
      <div className="container my-3  " style={props.Mystyle}>
        {" "}
        <h1>Enter the text</h1>
        <div className="container  "  >
          <div className="mb-3 ">
            <label htmlFor="MyBox" className="form-label"></label>
            <textarea value={text} style={props.Mystyle} placeholder='enter text here' onChange={handlechg} className="form-control" id="MyBox" rows="8"></textarea>
            <button onClick={onclk} className="btn btn-primary my-4 info"> convert to upper case</button>
            <button onClick={onclkclear} className="btn btn-primary my-4 mx-3 info"> clear</button>

          </div>
        </div>
        <div className="container " >

          <h1>Text summary</h1>

          <p>{text.split(" ").filter(word => word !== "").length}  words and {text.length} characters</p>
          <h3>Preview</h3>
          <p>{text}</p>
        </div>


      </div>
    

    </>
  )
}
