import React, { useState } from "react";
import "./App.css";
import Navbar from "./component/Navbar";
import Alert from "./component/Alert";
import TextForm from "./component/TextForm_info";
import About from "./component/About";
import {
  BrowserRouter as Router,
  Switch,
  Route

} from "react-router-dom";

function App() {
  const [mode, setMode] = useState("light");
  const [name, setName] = useState("Enable Dark mode");
  const [dfFOnt, setFont] = useState("black");
  const [alert, setAlert] = useState(null);
  const [themes, setTheme] = useState("dark");

  const [Mystyle, setMymode] = useState({
    color: "black",
    backgroundColor: "white",
  });
  const [currentBtn, Togglebtn] = useState("dark mode");
  function toggleDARk() {
    if (Mystyle.color === "black") {
      setMymode({
        color: "white",
        backgroundColor: "rgb(7 12 16)",
        border: "1px solid yellow",
        borderRadius: "5px"
      });
      Togglebtn("light mode")
      // alrtFunc("Inner Dark mode enabled", "info")



    } else {
      setMymode({
        color: "black",
        backgroundColor: "white",
      });
      Togglebtn("Dark mode")
      // props.alrtFunc("Inner Dark mode Disabled", "info")

    }
  }

  function setcolor0() {


    if (mode === "dark") {
      setTheme(
        document.body.style.backgroundColor = "blue"
      )
    }
    else {

      setTheme(themes)
    }
  }

  function setcolor1() {

    if (mode === "dark") {
      setTheme(
        document.body.style.backgroundColor = "#6c757d"
      )
    }
    else {

      setTheme(themes)
    }
  }
  function setcolor2() {
    if (mode === "dark") {
      setTheme(
        document.body.style.backgroundColor = "#198754"
      )
    }
    else {

      setTheme(themes)
    }
  }
  function setcolor3() {
    if (mode === "dark") {
      setTheme(
        document.body.style.backgroundColor = "#dc3545"
      )
    }
    else {

      setTheme(themes)
    }

  }
  function setcolor4() {

    if (mode === "dark") {
      setTheme(
        document.body.style.backgroundColor = "#ffc107"
      )
    }
    else {

      setTheme(themes)
    }
  }
  function setcolor5() {
    if (mode === "dark") {
      setTheme(
        document.body.style.backgroundColor = "#0dcaf0"
      )
    }
    else {

      setTheme(themes)
    }
  }
  function setcolor6() {
    if (mode === "dark") {
      setTheme(
        document.body.style.backgroundColor = "#f8f9fa"
      )
    }
    else {

      setTheme(themes)
    }
  }
  function setcolor7() {

    if (mode === "dark") {
      setTheme(
        document.body.style.backgroundColor = "#212529"
      )
    }
    else {

      setTheme(themes)
    }
  }
  function changeAlert(msg, typ) {
    setAlert({
      message: msg,
      type: typ
    });
    setTimeout(() => {
      setAlert(null)
    }, 1500);

  }
  function togglethemes() {
    if (mode === "light") {
      setMode("dark");
      setName("Enable Light mode");
      setFont("white");
      changeAlert("Dark mode enabled", "success");
      document.body.style.backgroundColor = "#042743";
      toggleDARk() 
      // getstyle.backgroundColor='042743'
      // document.getElementsByClassName(".container")[0].style.backgroundColor="#042743"
    } else {
      setMode("light");
      setName(" Enable Dark mode");
      setFont("black");
      changeAlert("light mode enabled", "success");
      toggleDARk()
      document.body.style.backgroundColor = "white";
    }
  }

  return (
    <>
      <Router>
        <Navbar
          setcolor0={setcolor0}
          setcolor1={setcolor1}
          setcolor2={setcolor2}
          setcolor3={setcolor3}
          setcolor4={setcolor4}
          setcolor5={setcolor5}
          setcolor6={setcolor6}
          setcolor7={setcolor7}
          title="TeRRoRop"
          fontch={dfFOnt}
          modes={mode}
          names={name}
          toggletheme={togglethemes}
        />
        <Alert alert={alert} />



        <div className="container">

          <Switch>
            <Route exact path="/about">
              <About Mystyle={Mystyle} fontch={dfFOnt} />
            </Route>
            <Route exact path="/">
          <TextForm toggleDARk={toggleDARk} currentBtn={currentBtn} Mystyle={Mystyle} alrtFunc={changeAlert} />
            </Route>

          </Switch>


        </div>
      </Router>

    </>
  );
}


export default App;
