import { useState } from "react";
// import React,[useState] from "react";
import PropTypes from "prop-types";
import { Link } from "react-router-dom/cjs/react-router-dom.min";
// import { Link } from "react-router-dom/cjs/react-router-dom.min";
// import { Link } from "react-router-dom";

export default function Navbar(props) {
  const [isVisible, setIsVisible] = useState(false);

  const handleButtonClick = () => {
    setIsVisible(!isVisible);
  };
  return (
    <nav
      className={`navbar  navbar-expand-lg navbar-${props.modes} bg-${props.modes}`}
    >
      <div className="container-fluid">
        <Link className="navbar-brand" to="/">
          {props.title}
        </Link>
        <button
          className="navbar-toggler"
          type="button"
          data-bs-toggle="collapse"
          data-bs-target="#navbarSupportedContent"
          aria-controls="navbarSupportedContent"
          aria-expanded="false"
          aria-label="Toggle navigation"
        >
          <span className="navbar-toggler-icon"></span>
        </button>
        <div className="collapse navbar-collapse" id="navbarSupportedContent">
          <ul className="navbar-nav me-auto mb-2 mb-lg-0">
            <li className="nav-item">
              <Link className="nav-link active" aria-current="page" to="/">
                Home
              </Link>
            </li>
            <li className="nav-item">
              <Link className="nav-link" to="/about">
                About
              </Link>
            </li>
          </ul>


          {isVisible && (
          <div className="mx-3">
            <button onClick={props.setcolor0} type="button" className="btn btn-primary mx-1 "></button>
            <button onClick={props.setcolor1} type="button" className="btn btn-secondary mx-1 "></button>
            <button onClick={props.setcolor2} type="button" className="btn btn-success mx-1"></button>
            <button onClick={props.setcolor3} type="button" className="btn btn-danger mx-1"></button>
            <button onClick={props.setcolor4} type="button" className="btn btn-warning mx-1"></button>
            <button onClick={props.setcolor5} type="button" className="btn btn-info mx-1"></button>
            <button onClick={props.setcolor6} type="button" className="btn btn-light mx-1"></button>
            <button onClick={props.setcolor7} type="button" className="btn btn-dark mx-1 border"></button>
          </div>
        )}
        <div className="form-check form-switch  ">
          <input
            onChange={props.toggletheme}
            onClick={handleButtonClick}
            className="form-check-input "
            type="checkbox"
            role="switch"
            id="flexSwitchCheckDefault"
          />
          <label
            className={`form-check-label text-${props.fontch}`}
            htmlFor="flexSwitchCheckDefault"
          >
            {props.names}
          </label>

        </div>
        </div>
        

      </div>

    </nav>
  );
}
Navbar.propTypes = {
  title: PropTypes.string.isRequired,
};
