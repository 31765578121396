import React from 'react'

export default function Alert(props) {
  return (

    props.alert && <div className={`alert alert-${props.alert.type} alert-dismissible fade show`} role="alert">
      <strong>Done</strong> :{props.alert.message}
      
    </div>

  )
}
