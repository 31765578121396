import React from 'react'

export default function About(props) {
  return (
    <>
      <h1 className={`text-${props.fontch}`}>About Us</h1>
      <div  className="accordion accordion-flush" id="accordionFlushExample">
        <div style={props.Mystyle} className="accordion-item">
          <h2 className="accordion-header" id="flush-headingOne">
            <button style={props.Mystyle} className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#flush-collapseOne" aria-expanded="false" aria-controls="flush-collapseOne">
              <strong>Analyze your Text</strong>
            </button>
          </h2>
          <div  id="flush-collapseOne" className="accordion-collapse collapse" aria-labelledby="flush-headingOne" data-bs-parent="#accordionFlushExample">
            <div  className="accordion-body">Textutils gives you a way to analyze text Quickelt and efficiently,Be it word count, character count</div>
          </div>
        </div>
        <div style={props.Mystyle} className="accordion-item">
          <h2 className="accordion-header" id="flush-headingTwo">
            <button style={props.Mystyle} className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#flush-collapseTwo" aria-expanded="false" aria-controls="flush-collapseTwo">
           <strong>Free to use</strong>
            </button>
          </h2>
          <div id="flush-collapseTwo" className="accordion-collapse collapse" aria-labelledby="flush-headingTwo" data-bs-parent="#accordionFlushExample">
            <div className="accordion-body"> Text utils provide an efficient way to write and count text in real-time  </div>
          </div>
        </div>
        <div  style={props.Mystyle}  className="accordion-item">
          <h2 className="accordion-header" id="flush-headingThree">
            <button  style={props.Mystyle} className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#flush-collapseThree" aria-expanded="false" aria-controls="flush-collapseThree">
            <strong> Browser Compatible</strong>
            </button>
          </h2>
          <div id="flush-collapseThree" className="accordion-collapse collapse" aria-labelledby="flush-headingThree" data-bs-parent="#accordionFlushExample">
            <div className="accordion-body">This Word counter software work in any Browser such as chrome , Firefox , Internet explorer, opera mini etc.</div>
          </div>
        </div>
      </div>
    </>
  )
}
